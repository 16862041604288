$(document).ready(function() {
	$('body,html').stop().scrollTop(0);
});

/* ----------------
	スムーススクロール
	----------------- */
$('.js-sote_anchor').on('click', function(e) {
	e.preventDefault();
	smoothScroll(this);
});

/* ----------------
	スムーススクロール
	----------------- */
var smoothScroll = function(anchor) {
	var $this = $(anchor),
		$ID = $this.attr('href'),
		_headerHeight, _Position;

	if (!$($ID).hasClass('is-hide')) {
		_headerHeight = $('#js-header').outerHeight(),
		_Position = $($ID).offset().top;

		if ($(window).width() > 768) {
			_Position -= _headerHeight;
		}

		$('html,body').animate({scrollTop: _Position}, 900);
	}
};
$(function() {
	// セレクトアンカー
	var $selectAnchor = $('.js-genre__select');
	$selectAnchor.on('change', function(e) {
		var target = $(this).val();
		setTimeout(function() {
			if (target.substring(0, 1) === '#') {
				if ($(target).length) {
					$('body,html').animate({scrollTop: $(target).offset().top}, 900);
				}
			} else {
				location.href = target;
			}
		}, 300);
	});
});

// 該当しないメッセージ
var wrapper = $('.js-store__list');
var showHideWrapper = function() {
	wrapper.each(function() {
		var _self = $(this);
		if (_self.find('.grid > .grid-item:not(.is-hide)').length > 0) {
			_self.removeClass('is-inactive');
			_self.next().removeClass('is-active');
		} else {
			_self.addClass('is-inactive');
			_self.next().addClass('is-active');
		}
	});
};

/* 絞り込み */
var $storeList = $('.js-store__list');

function filtering() {
	var $displayType = [];
	$('[name=filtering]').each(function() {
		if ($(this).prop('checked')) {
			$displayType.push($(this).val());
			$(this).parents('.filter-item').addClass('is-active');
		} else {
			$(this).parents('.filter-item').removeClass('is-active');
		}
	});

	$storeList.each(function() {
		var $storeListItem = $(this).find('.search-result');

		$storeListItem.each(function() {
			var $itemType = $(this).data('type'),
				_checkFlag;
			for (var i = 0; i < $displayType.length; i++) {
				if ($itemType.indexOf($displayType[i]) <= -1) {
					_checkFlag = false;
				}
			}
			if (_checkFlag === false) {
				/* 隠すときの処理(現行に寄せる) */
				$(this).parent('.grid-item').addClass('is-hide');
			} else {
				/* 表示するときの処理（現行に寄せる） */
				$(this).parent('.grid-item').removeClass('is-hide');
			}
		});
	});
}

$('[name=filtering]').on('change', function(e) {
	filtering();
	showHideWrapper();
});
